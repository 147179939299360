<template>
  <div class="home-lojas">
    <mf-loading-dialog :loading="$apollo.loading">
      <v-container class="pa-0" fluid>
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" class="pt-0 mt-0" hide-details single-line="" placeholder="Busca" append-icon="search" outlined />
            <v-spacer />
            <mf-button :disabled="isFranchise" label="Adicionar Loja" @click="triggerAddUnit" />
          </v-card-title>
          <v-card-text>
            <v-row justify="end" class="mt-n6">
              <v-col cols="auto">
                <headers-data-table
                  :headers.sync="headers"
                  :default-headers="getDefaultHeadersOptions()"
                  headers-settings-name="headers-list-units-retailers"
                />
              </v-col>
            </v-row>
            <v-data-table
              fixed-header
              :loading="$apollo.loading"
              :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
              :options.sync="options"
              :headers="computedHeaders"
              :items="unitsDataItems"
              item-class="clickable-row"
            >
              <template v-slot:item="{ item }">
                <tr :class="['clickable-row']" @click="selectUnit(item)">
                  <td>
                    <v-icon class="mr-2" x-small :color="getUnitStatusLabel(item.active).color">mdi-brightness-1</v-icon
                    >{{ getUnitStatusLabel(item.active).label }}
                  </td>
                  <td>
                    {{ item.id_erp }}
                  </td>
                  <td>
                    {{ item.cnpj | cnpj }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-icon class="mr-2" x-small :color="item.cbm ? 'success' : 'error'">mdi-brightness-1</v-icon>
                  </td>
                  <td>
                    <v-icon class="mr-2" x-small :color="item.mercashop ? 'success' : 'error'">mdi-brightness-1</v-icon>
                  </td>
                  <td>
                    <v-icon class="mr-2" x-small :color="item.mercapromo ? 'success' : 'error'">mdi-brightness-1</v-icon>
                  </td>
                  <td>
                    <v-icon class="mr-2" x-small :color="item.promotrade ? 'success' : 'error'">mdi-brightness-1</v-icon>
                  </td>
                  <td>
                    {{ item.created_at | date }}
                  </td>
                  <td>
                    {{ item.updated_at | date }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-container>
      <edit-unit
        v-model="adding"
        :dbName="dbName"
        admin-view
        is-new
        :client-units="clientUnits"
        :unit="newUnit"
        :saveCallback="addUnit"
        @close="adding = false"
      />
      <edit-unit
        v-model="editing"
        admin-view
        :dbName="dbName"
        :unit="selectedUnit"
        :saveCallback="saveSelectedUnit"
        :client-units="clientUnits"
        :is-franchise="isFranchise"
        @close="editing = false"
      />
    </mf-loading-dialog>
  </div>
</template>

<script>
import { MUTATION_ADD_UNIT, MUTATION_REMOVE_UNIT, MUTATION_UNIT, QUERY_UNITS, QUERY_CLIENT_UNITS, QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
import { deepDelete } from '@/helpers/deepDelete'
import { diacriticSpaceSensitiveRegex } from '@/helpers/search.js'
import { headers } from '@/mixin'
import { cloneDeep } from 'lodash'

export default {
  name: 'Units',
  components: {
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable'),
    EditUnit: () => import('@/modules/accounts/components/units/EditUnit.vue')
  },
  mixins: [headers],
  data: () => ({
    adding: false,
    search: '',
    newUnit: {},
    accountId: '',
    selectedUnit: {},
    editing: false,
    options: {},
    headers: [],
    unitsData: [],
    dbName: '',
    clientId: '',
    clientUnits: null
  }),
  computed: {
    isFranchise() {
      return localStorage.getItem('is_franchise') === 'true'
    },
    unitsDataItems() {
      let filteredUnits = this.unitsData
      if (this.search) {
        filteredUnits = filteredUnits.filter(item => {
          const digitSearch = this.search.replace(/\D/g, '')
          const digitSearchRegex = new RegExp(digitSearch)
          const searchRegex = diacriticSpaceSensitiveRegex(this.search)

          return (
            (digitSearch && digitSearchRegex.test(item.id_erp.replace(/\D/g, ''))) ||
            (digitSearch && digitSearchRegex.test(item.cnpj.replace(/\D/g, ''))) ||
            searchRegex.test(item.name)
          )
        })
      }
      return filteredUnits
    },
    tableHeaders() {
      return [
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          width: '5%',
          isActive: true
        },
        {
          text: 'ID ERP',
          value: 'id_Erp',
          width: '5%',
          isActive: true
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          width: '10%',
          isActive: true
        },
        {
          text: 'Nome',
          value: 'name',
          width: '12%',
          isActive: true
        },
        {
          text: 'CBM',
          value: 'cbm',
          width: '5%',
          isActive: true
        },
        {
          text: 'Mercashop',
          value: 'mercashop',
          width: '6%',
          isActive: true
        },
        {
          text: 'Mercapromo',
          value: 'mercapromo',
          width: '6%',
          isActive: true
        },
        {
          text: 'Promotrade',
          value: 'promotrade',
          width: '6%',
          isActive: true
        },
        {
          text: 'Criada em',
          value: 'created_at',
          width: '10%',
          isActive: true
        },
        {
          text: 'Atualizada em',
          value: 'updated_at',
          width: '10%',
          isActive: true
        }
      ]
    }
  },
  apollo: {
    units: {
      query: QUERY_UNITS,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update({ units }) {
        this.unitsData = units
      }
    },
    getClientUnits: {
      query: QUERY_CLIENT_UNITS,
      fetchPolicy: 'network-only',
      skip() {
        return !this.clientId
      },
      variables() {
        return { client_units: { client_id: this.clientId } }
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update(data) {
        this.clientUnits = data.clientUnits
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      update(data) {
        if (data?.adminAccount?.franchise_id) {
          this.dbName = data?.adminAccount?.db_name
        }
        this.clientId = data?.adminAccount?.client_id
      }
    }
  },
  mounted() {
    document.title = 'Configurações de Lojas'
    this.accountId = this.$route.params.id
  },
  methods: {
    selectUnit(unit) {
      this.selectedUnit = unit
      this.editing = true
    },
    getUnitStatusLabel(active) {
      if (active) {
        return { label: 'Ativa', color: '#07DD18' }
      } else {
        return { label: 'Inativa', color: '#FF5252' }
      }
    },
    triggerAddUnit() {
      this.newUnit = {}
      this.adding = true
    },
    getDefaultHeadersOptions() {
      return cloneDeep(this.tableHeaders)
    },
    async addUnit(unit) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_ADD_UNIT,
        variables: unit,
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.adding = false
      this.$apollo.queries.units.refetch()
    },
    async removeUnit(unit) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_REMOVE_UNIT,
        variables: {
          unit_id: unit._id
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.$apollo.queries.units.refetch()
    },
    async saveSelectedUnit(unit, unitId, account = undefined) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_UNIT,
        variables: {
          ...unit,
          unit_id: unitId
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: account || this.accountId
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.editing = false
      this.$apollo.queries.units.refetch()
    }
  }
}
</script>

<style lang="scss">
.home-lojas {
  .buttons-row {
    padding: 5px 30px 5px 30px;
  }
  .section-title {
    font-size: 20px;
    font-weight: bold;
  }
  .detail-title {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    color: #000000;
    margin-bottom: 8px;
  }
  .color-box {
    width: 24px;
    height: 24px;
    border: 1px solid #515151;
    border-radius: 4px;
    text-align: center;
    span {
      color: #515151;
      font-weight: bold;
    }
  }
  .alert-chip {
    display: block;
    max-width: 100px;
  }
}
.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f5f5f5; /* Optional: highlight row on hover */
}
</style>
